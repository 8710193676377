@use '/styles/mixin/imageWrapper.module.scss' as *;
@use '/styles/mixin/considerModel.module.scss' as considerModel;
@use '/styles/mixin/fonts.module.scss' as *;
.drive-car-thumbnail {
  &__container {
    @apply w-272px;
    @apply h-288px;
    @apply flex;
    @apply flex-col;
    @apply max-w-68;
    @apply shadow;
    @apply cursor-pointer;
  }
  &__variant-count {
    @apply block;
    @apply text-gray-400;
    @include font-caption('mobile', 'normal');
  }
}

.drive-car-thumbnail__deals {
  @apply bg-white;
  @apply p-4;
  @apply pt-5;
  @apply flex;
  @apply flex-col;
  @apply h-32;
  @apply relative;
  &__title {
    @include font-subtext('desktop', 'large');
    @apply transition-colors;
    @apply duration-500;
    @apply mt-0;
    @apply text-black;
    &:hover {
      @apply text-blue-dark;
    }
  }
}

.drive-car-thumbnail__make-img {
  @apply w-12;
  @apply h-12;
  @apply flex;
  @apply justify-center;
  @apply items-center;
  @apply z-10;
  @apply overflow-hidden;
  @apply absolute;
  @apply bottom-0;
  @apply right-0;
  @apply translate-y-6;
  @apply mr-2;
  @apply border;
  @apply border-gray-300;
  @apply rounded-full;
  @apply bg-white;
}

.drive-car-thumbnail__featured-img {
  @apply h-155px;
  @apply w-272px;
  @apply relative;
  &__sticky {
    @apply capitalize;
    @apply absolute;
    @apply m-1;
    @apply z-10;
    @apply right-0;
    @apply text-gray-400;
    @include font-caption('mobile', 'bold');
    @screen md {
      @include font-caption('desktop', 'normal');
    }
  }
  &__tag {
    @apply absolute;
    @apply top-0;
    @apply right-0;
    @apply px-4;
    @apply py-2;
    @apply text-white;
    @apply bg-black;
    @apply z-10;
    @apply opacity-25;
    @include font-caption('mobile', 'bold');
    @screen md {
      @include font-caption('desktop', 'normal');
    }
  }
  &-wrapper {
    @include img-wrapper;
  }
}
