@use '/styles/mixin/fonts.module.scss' as *;
.d-comparison-cta {
  @apply relative;
  @screen l {
    @apply max-w-[1440px];
    @apply mx-auto;
  }

  &:global(.homepage) {
    @apply mt-[22px];
    @screen md {
      @apply mt-[28px];
    }
    @screen lg {
      @apply mt-0;
    }
    @apply w-full;

    &::after {
      width: 100vw;
      left: calc(-50vw + 50%);
    }

    [class*='comparisonCta_d-comparison-cta__wrapper'] {
      @apply px-0;
    }

    &:global(.has-pto) {
      @apply z-0;
      @apply pr-4;
      &::after {
        @apply w-full;
        left: 0;
      }
    }
  }

  &::after {
    content: '';
    @apply bg-gray-200;
    @apply absolute;
    height: calc(100% - 130px);
    @apply w-screen;
    @apply left-0;
    @apply z-[-1];
    @apply bottom-0;

    @screen md {
      @apply h-[202px];
      top: 50%;
      transform: translate(0, -50%);
    }
    @screen lmd {
      @apply h-[160px];
    }
    @screen lg {
      //edge to edge
      @apply w-screen;
      @apply h-[206px];
      @apply my-0;
    }
    @screen l {
      // stop until 1440
      @apply w-full;
      @apply mx-auto;
    }
  }

  &__wrapper {
    @apply flex;
    @apply flex-col;
    @apply justify-between;
    @apply items-center;
    @apply relative;
    @apply w-full;
    @apply px-5;
    @apply max-w-[1240px];

    @screen md {
      @apply px-[56px];
      @apply flex-row;
    }

    @screen xl {
      @apply p-0;
      @apply mx-auto;
    }
  }

  &__right-wrapper {
    @apply flex;
    @apply flex-col;
    @apply flex-grow;
    @apply justify-between;
    @apply items-start;

    @screen md {
      @apply ml-10;
    }

    @screen lmd {
      @apply items-center;
      @apply flex-row;
      @apply ml-[26px];
    }

    @screen lg {
      @apply ml-[66px];
    }
  }

  &__img-wrapper {
    @apply max-w-[374px];
    @apply h-full;
    @apply relative;

    @screen md {
      @apply h-auto;
      @apply min-w-[280px];
      @apply max-w-[280px];
    }

    @screen xl {
      @apply min-w-[409px];
    }
  }

  &__text-wrapper {
    @apply text-center;
    @screen md {
      @apply text-left;
    }
    @screen lmd {
      @apply w-[320px];
    }
    @screen lg {
      @apply w-[394px];
    }
    h2 {
      @include font-heading('desktop', 'h3');
      @apply mt-6;
      @apply mb-0;
      @screen md {
        @apply m-0;
        @include font-heading('mobile', 'h4');
      }
      @screen lmd {
        @include font-heading('desktop', 'h4');
      }
      @screen lg {
        @include font-heading('desktop', 'h3');
      }
    }

    p {
      @include font-text('desktop', 'medium');
      @screen md {
        @include font-text('mobile', 'medium');
        @apply my-4;
      }
      @screen lmd {
        @apply mt-4;
        @apply mb-0;
        @include font-text('desktop', 'medium');
      }
    }
  }

  a {
    @apply bg-blue-dark;
    @apply text-center;
    @apply text-white;
    @include font-button();
    @apply h-12;
    @apply py-4;
    @apply rounded-lg;
    @apply w-full;
    @apply my-6;

    @screen md {
      @apply w-[192px];
      @apply m-0;
    }
  }
}
