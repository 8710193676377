@use '/styles/mixin/fonts.module.scss' as *;
.drive-price-range {
  &__container {
    @apply absolute;
    @apply bottom-0;
    @apply mb-2;
    @apply w-full;
  }
  &__range {
    @include font-subtext('mobile', 'large');
    @screen md {
      @include font-subtext('desktop', 'medium');
    }
  }
  &__range-type {
    @include font-caption('mobile', 'normal');
    @screen md {
      @include font-caption('desktop', 'normal');
    }
    @apply text-gray-400;
    @apply ml-2;
  }
}
