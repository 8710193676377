@mixin img-wrapper {
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply h-full;
  @apply w-full;
  @apply relative;

  & > span:first-child,
  img:not(:global(.drive-icon)) {
    width: 100% !important; // To override inline styles by React lazyload package.
    height: 100% !important;
    object-fit: cover;
  }
}
